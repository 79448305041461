import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";

import RenderMarkdown from "./utilities/RenderMarkdown";
import CountdownTimer from "./CountdownTimer";
import CTA from "./CTA";
import useFetch from "../functions/hooks/useFetch.js";
import hasQueryParam from "../functions/hasQueryParam";
import getQueryParam from "../functions/getQueryParam";
import numToEuroString from "../functions/numToEuroString";

function equalizeArrayLength(arr1, arr2) {
  if (arr1.length > arr2.length) {
    const temp = arr2.length;
    arr2.length = arr1.length;
    arr2.fill(null, temp);
  } else {
    const temp = arr1.length;
    arr1.length = arr2.length;

    arr1.fill(null, temp);
  }
}

export default function PricingTim({
  pricingObject,
  isAlwaysOffer = false,
  hasSecondPaymentIcons,
}) {
  const [isOffer, setIsOffer] = useState(false);

  const offerObject = {
    code: isAlwaysOffer
      ? pricingObject.codeOffer
      : getQueryParam("offer") || pricingObject.codeOffer,
  };

  const url = `https://order.api.prod.achtsamkeitsakademie.de/vouchers/${offerObject.code}`;

  const convertEurToCt = 100;
  const convertMonthlyToYearlyPrice = 10;
  const monthInYear = 12;
  const daysInYear = 365;
  const regularPricePerMonth = pricingObject.regularPricePerMonth || 49.40;
  const regularPriceYearly = pricingObject.pricingCardRecommended.price;
  const offerData = useFetch(url, {}, isOffer);

  if (offerData) {
    if (offerData?.voucher?.status !== "ACTIVE") {
      setIsOffer(false);
    }

    if (
      Date.now() < offerData?.voucher?.validFrom ||
      (offerData?.voucher?.expiresAt &&
        offerData?.voucher?.expiresAt < Date.now())
    ) {
      setIsOffer(false);
    }

    // if firstAmountMicros is falsy, calculate firstAmountMicros from firstRateMicros if possible otherwise set isOffer to false
    if (!offerData?.voucher?.firstAmountMicros) {
      if (offerData?.voucher?.firstRateMicros) {
        // to get firstAmountMicros from firstRateMicros result has to be divided by 100 -> convertEurToCt
        offerData.voucher.firstAmountMicros =
          (regularPricePerMonth * offerData?.voucher?.firstRateMicros) /
          convertEurToCt;
      } else {
        setIsOffer(false);
      }
    }
  }

  // calculate pricing values for offer
  const firstAmountInEUR =
    offerData?.voucher?.firstAmountMicros / convertEurToCt;

  const offerPriceFirstMonth = regularPricePerMonth - firstAmountInEUR;

  const offerPriceFirstYear = regularPriceYearly - firstAmountInEUR;

  const regularPriceYearlyPerMonth = regularPriceYearly / monthInYear;

  const savingsYearlyOffer =
    regularPricePerMonth * monthInYear - regularPriceYearly + firstAmountInEUR;

  // default texts
  const descriptionDefault = `## Das bekommst du alles für nur ${numToEuroString(
    regularPriceYearly / daysInYear
  )} pro Tag`;
  // TODO: maybe extend defaults text with default text for priceHint and priceText (for monthly/yearly payment)

  // set default texts - either from pricingObject or default
  const description = pricingObject.description || descriptionDefault;

  // offer default texts
  const descriptionOfferDefault = `## **AKTION:** Erhalte jetzt deinen ersten Monat in der Achtsamkeitsakademie für nur ${numToEuroString(
    offerPriceFirstMonth
  )}!`;
  const priceOfferHintMonthlyDefault = `Ab dem 2. Monat ${numToEuroString(
    regularPricePerMonth
  )}.`;
  const priceOfferHintYearlyDefault = `Jährliche Zahlung.
  
  
  Du sparst dir im ersten Jahr **${numToEuroString(
    savingsYearlyOffer
  )}** und ab dem zweiten Jahr ${numToEuroString(
    regularPricePerMonth * 12 - regularPriceYearly
  )}.`;
  // there is no footNoteOffer coming from .md yet, therefore footNoteOfferDefault gets passed down to PriceFooter and displayed directyl
  const footNoteOfferDefault = `Spare **${numToEuroString(
    savingsYearlyOffer
  )}** im ersten Jahr.`;

  // set offer texts - either from pricingObject or default
  offerObject.description =
    pricingObject.descriptionOffer || descriptionOfferDefault;
  offerObject.priceTextMonthly =
    pricingObject.pricingCardRegular.priceOfferHint ||
    priceOfferHintMonthlyDefault;
  offerObject.priceTextYearly =
    pricingObject.pricingCardRecommended.priceOfferHint ||
    priceOfferHintYearlyDefault;

  useEffect(() => {
    if (isAlwaysOffer || hasQueryParam("offer")) {
      setIsOffer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if isOffer=true, but there is no offer code, then set isOffer false
    if (isOffer && !offerObject.code) {
      setIsOffer(false);
    }
  }, [offerObject]);

  equalizeArrayLength(
    pricingObject.pricingCardRegular.featuresList,
    pricingObject.pricingCardRecommended.featuresList
  );

  return (
    <>
      <div className="space-y-5 sm:space-y-10">
        <RenderMarkdown
          markdownContent={isOffer ? offerObject.description : description}
          className="text-center"
        />
        {isOffer && offerData?.voucher?.expiresAt && (
          <div className="!mb-10 md:mb-0">
            <CountdownTimer
              className="m-2 xl:m-5"
              countdownTitle={""}
              date={offerData.voucher.expiresAt}
              classNameDigits={"font-semibold text-offbrand-red"}
            />
          </div>
        )}
        <div
          className={
            "flex flex-col-reverse md:flex-row justify-center items-stretch w-full"
          }
        >
          {/* TODO: Add price as html attribute eventually */}
          <PriceCard
            priceCardObject={pricingObject.pricingCardRegular}
            isOffer={isOffer}
            offerCode={offerObject.code}
            priceOffer={isOffer && offerPriceFirstMonth}
            priceOfferHint={isOffer && offerObject.priceTextMonthly}
            footNoteOfferDefault={footNoteOfferDefault}
          />
          <PriceCard
            priceCardObject={pricingObject.pricingCardRecommended}
            isOffer={isOffer}
            offerCode={offerObject.code}
            priceOffer={isOffer && offerPriceFirstYear}
            priceOfferHint={isOffer && offerObject.priceTextYearly}
            footNoteOfferDefault={footNoteOfferDefault}
          />
        </div>
        {isOffer && (
          <div>
            <RenderMarkdown
              markdownContent={`###### Der Aktionscode "${offerObject.code}" ist auf dem Bestellformular bereits voreingestellt.`}
              className="text-center"
            />
          </div>
        )}
      </div>
      <div className="flex flex-row space-x-3 justify-center mt-10 md:mt-20 bg-white p-3 max-w-md mx-auto rounded-xl">
        {/* TODO: Switch back to static image when gatsby-plugin-image bug on safari with duplicate images is resolved */}
        {!hasSecondPaymentIcons ? (
          <>
            <StaticImage
              src="../img/logo-mastercard.png"
              loading="lazy"
              className="w-24 h-8"
              objectFit="contain"
              alt="Mastercard Logo"
              placeholder="none"
            />
            <StaticImage
              src="../img/logo-paypal.png"
              loading="lazy"
              className="w-24 h-8"
              objectFit="contain"
              alt="Paypal Logo"
              placeholder="none"
            />
            <StaticImage
              src="../img/logo-sepa.png"
              loading="lazy"
              className="w-24 h-8"
              objectFit="contain"
              alt="Sepa Logo"
              placeholder="none"
            />
            <StaticImage
              src="../img/logo-visa.png"
              loading="lazy"
              className="w-24 h-8"
              objectFit="contain"
              alt="Visa Logo"
              placeholder="none"
            />
          </>
        ) : (
          <>
            <StaticImage
              src="../img/logo-mastercard copy.png"
              loading="lazy"
              className="w-24 h-8"
              objectFit="contain"
              alt="Mastercard Logo"
              placeholder="none"
            />
            <StaticImage
              src="../img/logo-paypal copy.png"
              loading="lazy"
              className="w-24 h-8"
              objectFit="contain"
              alt="Paypal Logo"
              placeholder="none"
            />
            <StaticImage
              src="../img/logo-sepa copy.png"
              loading="lazy"
              className="w-24 h-8"
              objectFit="contain"
              alt="Sepa Logo"
              placeholder="none"
            />
            <StaticImage
              src="../img/logo-visa copy.png"
              loading="lazy"
              className="w-24 h-8"
              objectFit="contain"
              alt="Visa Logo"
              placeholder="none"
            />
          </>
        )}
      </div>
      <RenderMarkdown
        markdownContent={pricingObject.postScript}
        className="mt-8 text-center"
      />
    </>
  );
}

function PriceCard({
  priceCardObject,
  isOffer = false,
  offerCode = "",
  priceOffer,
  priceOfferHint = "",
  footNoteOfferDefault = "",
}) {
  // add voucher code to query param if isOffer=true, offerCode is present and ctaObject.linkUrl doesn't contain voucher code query param
  if (
    isOffer &&
    offerCode &&
    !hasQueryParam("voucher", priceCardObject.ctaObject.linkUrl)
  ) {
    priceCardObject.ctaObject.linkUrl = `${priceCardObject.ctaObject.linkUrl}${
      priceCardObject.ctaObject.linkUrl.includes("?") ? "&" : "?"
    }voucher=${offerCode}`;
  }

  return (
    <>
      <article
        className={`max-w-2xl rounded-3xl h-fit border-[0.5px] border-brand-beige-100 ${
          priceCardObject.isRecommended
            ? "shadow-lg -my-6 py-6 md:border-l-0"
            : "md:rounded-r-none md:border-r-0 mt-16 md:mt-0"
        }`}
      >
        <PriceHeader
          title={priceCardObject.title}
          price={priceCardObject.price}
          priceAdditive={priceCardObject.priceAdditive}
          priceHint={priceCardObject.priceHint}
          isRecommended={priceCardObject.isRecommended}
          isOffer={isOffer}
          priceOffer={priceOffer}
          priceOfferHint={priceOfferHint}
        />
        <PriceBody
          list={priceCardObject.featuresList}
          isRecommended={priceCardObject.isRecommended}
        />
        <PriceFooter
          ctaObject={priceCardObject.ctaObject}
          isRecommended={priceCardObject.isRecommended}
          footNote={priceCardObject.footNote}
          isOffer={isOffer}
          footNoteOfferDefault={footNoteOfferDefault}
        />
      </article>
    </>
  );
}

function PriceHeader({
  title,
  price,
  priceAdditive = "",
  priceHint,
  isRecommended = "",
  isOffer = false,
  priceOffer,
  priceOfferHint = "",
}) {
  return (
    <div
      className={`flex flex-col items-center p-8 rounded-t-3xl relative overflow-hidden ${
        isRecommended
          ? "bg-brand-green -mt-6 pt-14"
          : "bg-brand-olive md:rounded-tr-none"
      }`}
    >
      {isRecommended && (
        <div
          className={
            "absolute top-7 -right-10 text-center uppercase bg-brand-gold rotate-45"
          }
        >
          <RenderMarkdown
            markdownContent={"**Empfehlung**"}
            isMarkdown={false}
            className="flex flex-col justify-center px-8 py-2 text-sm"
          />
        </div>
      )}
      {/* // set fixed height of 2 lines (text-xl sm:text-3xl) and hide text overflow; TODO: maybe replace className with @tailwindcss/line-clamp plugin       */}
      <RenderMarkdown
        markdownContent={title}
        className="text-center box-content h-14 sm:h-20 "
      />
      <div className="relative">
        <RenderMarkdown
          markdownContent={`## **${numToEuroString(price)}** `}
          className={`text-center text-white ${!isOffer && "scale-150"} ${
            isOffer ? "line-through" : ""
          }`}
        />
        {isOffer ? (
          <>
            <RenderMarkdown
              markdownContent={`## **${numToEuroString(priceOffer)}** `}
              className={`text-center text-white scale-150`}
            />
            <RenderMarkdown
              markdownContent={priceAdditive}
              className="text-white text-center w-40 absolute bottom-0 right-1/2 transform translate-x-1/2 translate-y-1/2"
            />
          </>
        ) : (
          <RenderMarkdown
            markdownContent={priceAdditive}
            className="text-white text-center w-40 absolute bottom-0 right-1/2 transform translate-x-1/2 translate-y-1/2"
          />
        )}
      </div>

      {/* set fixed height of 2 lines (text-xs) on screens above screenwidth sm and hide text overflow as PriceHeader need to have same height on both offer cards regardless of content, when placed side by side (above sm) */}
      {/* TODO: maybe replace className with @tailwindcss/line-clamp plugin */}
      {/* fixed height of 3 lines, when isOffer=true because priceOfferHint is usually longer */}
      <RenderMarkdown
        markdownContent={isOffer ? priceOfferHint : priceHint}
        className={`mt-4 text-center text-white box-content h-auto ${
          isOffer ? "sm:h-28" : "sm:h-14"
        }`}
        whitespace="normal"
      />
    </div>
  );
}

function PriceBody({ list, isRecommended }) {
  return (
    <div className={`py-5 bg-white ${isRecommended ? "" : ""}`}>
      {list.map((item, index) => {
        return (
          <div
            key={index}
            // set fixed height of 2 or 3 lines (text-base sm:text-xl) and hide text overflow; TODO: maybe replace className with @tailwindcss/line-clamp plugin
            // TODO: logic deciding between 2 or 3 lines only for one side, not on both sides
            className={`box-content flex flex-col justify-center ${
              item.length < 80 ? "h-10 sm:h-14" : "h-12 sm:h-20"
            } px-8 py-3 ${index % 2 === 0 ? "bg-brand-beige-40" : "bg-white"}`}
          >
            <RenderMarkdown
              markdownContent={item}
              whitespace="normal"
              className="text-center align-middle "
            />
          </div>
        );
      })}
    </div>
  );
}

function PriceFooter({
  ctaObject,
  isRecommended = "",
  footNote = "",
  isOffer = false,
  footNoteOfferDefault = "",
}) {
  return (
    <div
      className={` p-8 rounded-b-3xl relative ${
        isRecommended ? "-mb-6 pb-14" : "md:rounded-br-none"
      }`}
    >
      <CTA ctaObject={ctaObject} />
      <RenderMarkdown
        markdownContent={isOffer && footNote ? footNoteOfferDefault : footNote}
        isMarkdown={false}
        whitespace="nowrap"
        className="absolute bottom-8 left-1/2 transform -translate-x-1/2 text-xs"
      />
    </div>
  );
}
